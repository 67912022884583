import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import axios from "axios";

const socket = io("https://customer-node.rupioo.com", {
  transports: ["websocket"],
  withCredentials: true,
});

const SuperAdminChatbox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [id, setId] = useState(null);
    const [selectedAdminId, setSelectedAdminId] = useState(null);
  

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      try {
        const parsedUser = JSON.parse(userData);
        setId(parsedUser?._id);
        setUserRole(parsedUser?.rolename?.roleName);
      } catch (error) {
        console.error("Error parsing userData:", error);
        setUserRole(null);
      }
    }
  }, []);

  useEffect(() => {
    if (!id) return; // Ensure ID is available before proceeding

    setRoomId(id);

    socket.emit("createRoom", id);
    console.log("Room Created:", id);

    socket.emit("joinRoom", id);
    console.log("Joined Room:", id);

    return () => {
      socket.emit("leaveRoom", id);
      console.log("Left Room:", id);
    };
  }, [id]); // Dependency added to prevent unnecessary execution

  const fetchChatHistory = async () => {
    try {
      setTimeout(async()=> {
        const response = await axios.get(
          `https://customer-node.rupioo.com/chat/view-chat/${id}`
        );
    
        if (response.data.status) {
          console.log('res', response)
          const messages = await response.data?.chat[0]?.messages
           console.log('mess', messages)
          setChatHistory(messages);
        }
      }, 2000)
    
    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  };

  useEffect(() => {
   
    // Fetch chat history initially
    fetchChatHistory();
  
    // Handle incoming socket messages
    const handleIncomingMessage = async(data) => {
      console.log("Received Data:", data);
      await fetchChatHistory();

    };
  
    socket.on("chatMessage", handleIncomingMessage);
  
    return () => {
      socket.off("chatMessage", handleIncomingMessage);
    };
  }, [id]); // Depend on id to refetch and listen for messages dynamically
  
  
  
 
  const toggleChatbox = () => {
    setIsOpen(!isOpen);
  };

  const sendMessage = () => {
    if (message.trim()) {
      const newMessage = { sender: "SuperAdmin", text: message, date: Date.now() };
  
      // Emit the message to the socket server
      socket.emit("chatMessage", {
        roomId,
        sender: "SuperAdmin",
        message: newMessage,  // Use the correct message structure
      });
  
      // Save the message to the server
      axios.post("https://customer-node.rupioo.com/chat/save-chat", {
        superadmin: id,
        messages: newMessage,  // Ensure messages is an array
      
      })
      .catch((error) => console.error("Error saving chat:", error));
  
      console.log("Message Sent:", message);
  
      // Fetch the updated chat history (if applicable)
      fetchChatHistory();
  
      // Clear the message input field
      setMessage("");
    }
  };
  

  if (userRole !== "SuperAdmin") return null;

  return (
    <div>
      <div
        onClick={toggleChatbox}
        style={{
          backgroundColor: "blue",
          color: "#fff",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          margin: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        💬
      </div>
      {isOpen && (
        <div
          style={{
            position: "fixed",
            bottom: "60px",
            right: "20px",
            width: "320px",
            height: "500px",
            backgroundColor: "#fff",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              backgroundColor: "blue",
              color: "#fff",
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontWeight: "bold",
            }}
          >
            <span>Chat with Master</span>
          </div>
          <div
            style={{
              flex: 1,
              overflowY: "auto",
              padding: "10px",
              borderBottom: "1px solid #ccc",
            }}
          >
            {chatHistory?.length > 0 ? (
                chatHistory?.map((msg, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: msg.sender === "SuperAdmin" ? "flex-end" : "flex-start",
                      marginBottom: "5px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: msg.sender === "SuperAdmin" ? "#f7c19b" : "#ddd",
                        color: msg.sender === "SuperAdmin" ? "black" : "black",
                        padding: "8px 12px",
                        borderRadius: "15px",
                        maxWidth: "80%",
                        wordBreak: "break-word", // Ensures long words wrap
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                      }}
                    >
                      {msg.text}
                      <div style={{ fontSize: '6px', color: 'black', marginTop: '5px', textAlign: 'right' }}>
                        {new Date(msg.date).toLocaleDateString()} {new Date(msg.date).toLocaleTimeString()}
                      </div>
                    </div>
                  </div>
                ))
  
            ) : (
              <div style={{ textAlign: "center", fontStyle: "italic", color: "#777" }}>
                Start chatting
              </div>
            )}
          </div>
          {/* Message Input & Send Button */}
          <div
            style={{
              display: "flex",
              padding: "10px",
              borderTop: "1px solid #ccc",
              backgroundColor: "#fff",
            }}
          >
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              style={{
                flex: 1,
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                marginRight: "8px",
              }}
              placeholder="Type a message..."
              onKeyDown={(e) => e.key === "Enter" && sendMessage()} // Send on Enter
            />
            <button
              onClick={sendMessage}
              style={{
                padding: "8px 12px",
                backgroundColor: "#007bff",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuperAdminChatbox;
