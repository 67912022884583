import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import axios from "axios";

const socket = io("https://customer-node.rupioo.com", {
  transports: ["websocket", "polling"],
  withCredentials: true,
});

const AdminChatbox = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [selectedAdminId, setSelectedAdminId] = useState(null);
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [superAdmins, setSuperAdmins] = useState([]);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      try {
        const parsedUser = JSON.parse(userData);
        setUserRole(parsedUser?.rolename?.roleName);
      } catch (error) {
        console.error("Error parsing userData:", error);
        setUserRole(null);
      }
    }
  }, []);

  useEffect(() => {
    axios
      .get("https://customer-node.rupioo.com/master/all-super-admin-list")
      .then((response) => {
        if (response.data.status) {
          setSuperAdmins(response.data.SuperAdmin);
        }
      })
      .catch((error) => console.error("Error fetching super admins:", error));
  }, []);

  useEffect(() => {
    if (selectedAdminId) {
      socket.emit("joinRoom", selectedAdminId);
      console.log("Joined Room:", selectedAdminId);

      return () => {
        socket.emit("leaveRoom", selectedAdminId);
        console.log("Left Room:", selectedAdminId);
      };
    }
  }, [selectedAdminId]);

  const fetchChatHistory = async () => {
    try {
      setTimeout(async () => {
        const response = await axios.get(
          `https://customer-node.rupioo.com/chat/view-chat/${selectedAdminId}`
        );

        if (response.data.status) {
          console.log('res', response)
          const messages = await response.data?.chat[0]?.messages
          console.log('mess', messages)
          setChatHistory(messages);
        }
      }, 2000)

    } catch (error) {
      console.error("Error fetching chat history:", error);
    }
  };

  useEffect(() => {

    // Fetch chat history initially
    fetchChatHistory();

    const handleIncomingMessage = async (data) => {
      console.log("Received Data:", data);
      await fetchChatHistory();

    };

    socket.on("chatMessage", handleIncomingMessage);

    return () => {
      socket.off("chatMessage", handleIncomingMessage);
    };
  }, [selectedAdminId]);




  const toggleChatbox = () => setIsOpen(!isOpen);

  const selectAdmin = (admin) => {
    setSelectedAdmin(admin.firstName);
    setSelectedAdminId(admin._id);
    setChatHistory([]);
  };

  const goBack = () => {
    setSelectedAdmin(null);
    setSelectedAdminId(null);
    setChatHistory([]);
  };


  const sendMessage = async () => {
    if (message.trim() && selectedAdminId) {
      const messages = { sender: "MASTER", text: message, date: Date.now() };

      // Emit message to socket
      socket.emit("chatMessage", {
        roomId: selectedAdminId,
        sender: "MASTER",
        messages,
      });

      try {
        // Wait for the message to be saved before fetching chat history
        await axios.post("https://customer-node.rupioo.com/chat/save-chat", {
          superadmin: selectedAdminId,
          messages,
        });

        // Now fetch the updated chat history
        await fetchChatHistory();
      } catch (error) {
        console.error("Error saving chat:", error);
      }

      // Clear input field
      setMessage("");
    }
  };


  if (userRole !== "MASTER") return null;

  return (
    <div>
      <div onClick={toggleChatbox} style={{ backgroundColor: "blue", color: "#fff", width: "40px", height: "40px", borderRadius: "50%", margin: "10px", display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}>💬</div>
      {isOpen && (
        <div style={{ position: "fixed", bottom: "60px", right: "20px", width: "320px", height: "500px", backgroundColor: "#fff", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", borderRadius: "10px", display: "flex", flexDirection: "column", overflow: "hidden" }}>
          <div style={{ backgroundColor: "blue", color: "#fff", padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center", fontWeight: "bold" }}>
            {selectedAdmin ? (
              <>
                <button onClick={goBack} style={{ background: "none", border: "none", color: "#fff", cursor: "pointer", fontSize: "18px" }}>⬅</button>
                <span>{selectedAdmin}</span>
              </>
            ) : (
              <span>Hi Master</span>
            )}
            <button onClick={toggleChatbox} style={{ background: "none", border: "none", color: "#fff", cursor: "pointer", fontSize: "18px" }}>✖</button>
          </div>
          <div style={{ flex: 1, overflowY: "auto", padding: "10px", borderBottom: "1px solid #ccc" }}>
            {!selectedAdmin ? (
              superAdmins.map((admin) => (
                <div key={admin._id} onClick={() => selectAdmin(admin)} style={{ padding: "10px", cursor: "pointer", borderBottom: "1px solid #ddd" }}>👤 {admin.firstName}</div>
              ))
            ) : (
              chatHistory.length > 0 ? (
                chatHistory.map((msg, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row", // Enables row layout if msg.row is true
                      justifyContent: msg.sender === "MASTER" ? "flex-end" : "flex-start",
                      alignItems: "flex-end",
                      marginBottom: "10px",
                      gap: "10px", // Adds space in row mode
                      backgroundColor: "white"
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: msg.sender === "MASTER" ? "#f7c19b" : "#ddd",
                        color: msg.sender === "MASTER" ? "black" : "black",
                        padding: "10px 15px",
                        borderRadius: "15px",
                        maxWidth: "75%",
                        wordBreak: "break-word", // Ensures long words wrap
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", // Soft shadow for depth
                      }}
                    >
                      {msg.text}
                      <div style={{ fontSize: '6px', color: 'black', marginTop: '5px', textAlign: 'right' }}>
                        {new Date(msg.date).toLocaleDateString()} {new Date(msg.date).toLocaleTimeString()}
                      </div>
                    </div>
                  </div>
                ))



              ) : (
                <div style={{ textAlign: "center", fontStyle: "italic", color: "#777" }}>Start chatting with {selectedAdmin}</div>
              )
            )}
          </div>
          {selectedAdmin && (
            <div style={{ display: "flex", padding: "10px", borderTop: "1px solid #ccc", backgroundColor: "#fff" }}>
              <input type="text" value={message} onChange={(e) => setMessage(e.target.value)} style={{ flex: 1, padding: "8px", border: "1px solid #ccc", borderRadius: "5px" }} placeholder="Type a message..." />
              <button onClick={sendMessage} style={{ marginLeft: "10px", padding: "8px 12px", backgroundColor: "#007bff", color: "#fff", border: "none", borderRadius: "5px", cursor: "pointer" }}>Send</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminChatbox;
