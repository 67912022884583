import React from "react"
import VerticalLayout from "../../layouts/VerticalLayout"
import FullLayout from "../../layouts/FullpageLayout"
import HorizontalLayout from "../../layouts/HorizontalLayout"
import themeConfig from "../../configs/themeConfig"
import AdminChatbox from "../../views/apps/freshlist/house/AdminChatbox"
import SuperAdminChatbox from "../../views/apps/freshlist/house/SuperAdminChatbox"

const layouts = {
  vertical: VerticalLayout,
  full: FullLayout,
  horizontal: HorizontalLayout
}

const ContextLayout = React.createContext()

class Layout extends React.Component {
  state = {
    activeLayout: themeConfig.layout,
    width: window.innerWidth,
    lastLayout: null,
    direction: themeConfig.direction,
    isLoggedIn: false,
    data: null
  }

  updateWidth = () => {
    this.setState({
      width: window.innerWidth
    })
  }

  handleWindowResize = () => {
    this.updateWidth()
    if (this.state.activeLayout === "horizontal" && this.state.width <= 1199) {
      this.setState({
        activeLayout: "vertical",
        lastLayout: "horizontal"
      })
    }

    if (this.state.lastLayout === "horizontal" && this.state.width >= 1199) {
      this.setState({
        activeLayout: "horizontal",
        lastLayout: "vertical"
      })
    }
  }

  fetchUserData = () => {
    const user = localStorage.getItem("userData")
    if (user) {
      try {
        const parsedUser = JSON.parse(user)
        this.setState({ isLoggedIn: true, data: parsedUser })
      } catch (error) {
        console.error("Error parsing user data:", error)
      }
    } else {
      this.setState({ isLoggedIn: false, data: null }) // If no user data, set state accordingly
    }
  }

  componentDidMount = () => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.handleWindowResize)
      window.addEventListener("storage", this.handleStorageChange) // Listen for localStorage changes
    }
    this.handleDirUpdate()
    this.fetchUserData()

    if (this.state.activeLayout === "horizontal" && this.state.width <= 1199) {
      this.setState({
        activeLayout: "vertical"
      })
    } else if (
      themeConfig.layout === "horizontal" &&
      this.state.width >= 1200
    ) {
      this.setState({
        activeLayout: "horizontal"
      })
    } else {
      this.setState({
        activeLayout: "vertical"
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.handleDirUpdate()
  console.log('component unmount')
  console.log('data',localStorage.getItem("userData"))
    // Check if userData was removed (logged out)
    if (!localStorage.getItem("userData") && prevState.isLoggedIn) {
      this.setState({ isLoggedIn: false, data: null })
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.handleWindowResize)
      window.removeEventListener("storage", this.handleStorageChange)
    }
  }

  handleStorageChange = () => {
    this.fetchUserData() // Fetch user data again when localStorage changes
  }

  handleDirUpdate = () => {
    let dir = this.state.direction
    if (dir === "rtl") {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl")
    } else {
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr")
    }
  }

  render() {
    const { children } = this.props
    const { isLoggedIn, data } = this.state

    return (
      <ContextLayout.Provider
        value={{
          state: this.state,
          fullLayout: layouts["full"],
          VerticalLayout: layouts["vertical"],
          horizontalLayout: layouts["horizontal"],
          switchLayout: (layout) => {
            this.setState({ activeLayout: layout })
          },
          switchDir: (dir) => {
            this.setState({ direction: dir })
          }
        }}
      >
        {children}
        {/* {isLoggedIn && data?.rolename?.roleName && (
          <>
            {data.rolename.roleName === "MASTER" ? <AdminChatbox /> : null}
            {data.rolename.roleName === "SuperAdmin" ? <SuperAdminChatbox />: null}
          </>
        )} */}

      </ContextLayout.Provider>
    )
  }
}

export { Layout, ContextLayout }
